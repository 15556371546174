import React, { ReactElement, useState, useEffect, useRef, useMemo } from 'react';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { v4 as uuid } from 'uuid';
import Textarea from '@rambler-components/textarea';
import Snackbar, { snackbarEvent } from '@rambler-components/snackbar';
import { useParams, useSearchParams } from 'react-router-dom';

import { ActivityBlock } from '@rambler-help/components';
import { PageBlock } from '@rambler-help/components';
import { ErrorMessage } from '@rambler-help/components';
import { Input } from '@rambler-help/components';
import { Button } from '@rambler-help/components';
import Back from '@components/Back';
import { useRootStore } from '@stores/index';
import { EModal } from '@rambler-help/shared';
import { Info } from '@rambler-help/components';
import { SITE_TITLE } from '@constants/meta';
import { isImage } from '@rambler-help/helpers';
import { RouteParameters } from '@rambler-help/shared';

import '@rambler-components/textarea/styles.css';
import '@rambler-components/snackbar/styles.css';

import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import {
  fontDefault, fontMontserrat,
  fontColorLink, fontColorDefault, fontColorLinkHover, fontColorLinkActive,
  colorDarkCloudGrey
} from '@rambler-help/shared';

const FeedbackFormPageStyled = styled.div`
  margin: 20px auto;
  ${fontMontserrat}
  h1 {
    margin: 30px 0 20px;
    ${fontMontserrat}
    ${fontColorDefault}
    font-size: 26px;
    line-height: 30px;
    font-weight: 800;
  }
  h2 {
    margin: 30px 0;
    ${fontMontserrat}
    ${fontColorDefault}
    font-size: 38px;
    line-height: 45px;
    font-weight: 800;
  }
  p {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    color: ${colorDarkCloudGrey};
  }
  a {
    ${fontColorLink}
    text-decoration: none;
  }
  a:hover {
    ${fontColorLinkHover}
    text-decoration: none;
  }
  a:active {
    ${fontColorLinkActive}
    text-decoration: none;
  }
  input[type="file"] {
    display: none;
  }
  label {
    position: relative;
    ${fontColorLink}
    font-weight: 600;
    cursor: pointer;
  }
  label::before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background-image: url('./images/file.svg');
    background-repeat: no-repeat;
  }
`;
const InfoWrapper = styled.div`
  width: 540px;
`;
const Preview = styled.div`
  height: 50px;
`;
const PreviewImage = styled.img`
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-size: cover;
`;
const PreviewName = styled.div`
  margin-left: 60px;
`;
const PreviewRemove = styled.div`
  margin-left: 60px;
  ${fontColorLink}
  font-weight: 600;
  cursor: pointer;
`;
const hidden = css`
  display: none;
`;
const formStyle1 = css`
  width: 540px;
  display: flex;
  input {
    width: calc(50% - 10px);
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const formStyle2 = css`
  textarea {
    height: 100px;
    width: 510px;
    ${fontMontserrat}
    font-size: 14px;
    line-height: 20px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 540px;
  padding: 40px;
  ${fontDefault}
  background-color: #eff5ff;
  border-radius: 15px;
`;
const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  ${fontDefault}
  font-size: 20px;
  font-weight: 800;
  line-height: 25px;
  & > div {
    margin-left: 25px;
  }
`;
const Description = styled.div``;

export type TKeyValuePairs = Array<Array<string>>;

const FeedbackForm = observer((): ReactElement => {
  const { layout, toplineUser, modals, projects, feedbackForms, feedbackRequests } = useRootStore();
  const { handleSubmit, control, watch, setValue, getValues, formState: { errors } } = useForm();
  const formIdParam = RouteParameters.FORM_ID.replace(':', '');
  const { formId } = useParams<{ [key in typeof formIdParam]?: string }>();
  const [feedbacksPerHourOverLimit, setFeedbacksPerHourOverLimit] = useState<boolean>(
    !!feedbackRequests.feedbacksPerHourOverLimit
  );
  const [feedbacksPerDayOverLimit, setFeedbacksPerDayOverLimit] = useState<boolean>(
    !!feedbackRequests.feedbacksPerDayOverLimit
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(true);
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
  const [formInitialValues, setFormInitialValues] = useState<FieldValues>();
  const [formValues, setFormValues] = useState<FieldValues>();
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [fileInfo, setFileInfo] = useState({ preview: '', previewName: '' });
  const [ params ] = useSearchParams();
  const [keyValuePairs, setKeyValuePairs] = useState<TKeyValuePairs>([]);
  const screenshotImageRef = useRef<HTMLInputElement | null>(null);
  const requestId = useRef(uuid());

  useEffect(() => {
    const localKeyValuePairs: Array<Array<string>> = [];
    for (const [key] of params) {
      const iStr = key.match(/\d+/)?.[0];
      const part = key.replace(/\d+/, '');
      if (iStr) {
        const i = parseInt(iStr);
        if (!localKeyValuePairs[i]) {
          localKeyValuePairs[i] = [];
        }
        localKeyValuePairs[i][part === 'dataLabel' ? 0 : 1] = params.get(key) || '';
      }
    }
    setKeyValuePairs(localKeyValuePairs);
  }, [params]);

  useEffect(() => {
    if (toplineUser.email) {
      setValue('email', toplineUser.email);
    }
  }, [toplineUser.email, setValue]);

  useEffect(() => {
    if (
      feedbackRequests.request?.id === requestId.current &&
      (feedbackRequests.request.isComplete || feedbackRequests.request.error)
    ) {
      setIsLoading(false);
      if (feedbackRequests.request.isComplete) {
        modals.show(EModal.FeedbackRequestSent);
      }
      if (feedbackRequests.feedbacksPerHourOverLimit) {
        setFeedbacksPerHourOverLimit(true);
      }
      if (feedbackRequests.feedbacksPerDayOverLimit) {
        setFeedbacksPerDayOverLimit(true);
      }
    }
  }, [feedbackRequests.request, modals, formInitialValues]);

  useEffect(() => {
    const values = getValues();
    setFormInitialValues(values);
    setFormValues(values);
  }, [getValues]);

  useEffect(() => {
    const subscription = watch((value) => {
      setFormValues(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setIsEdited(
      !(JSON.stringify(formInitialValues) === JSON.stringify(formValues))
    );
  }, [formInitialValues, formValues]);

  const updateFormFromState = useMemo(() => (data: Array<string>) => {
    if (formInitialValues) {
      data.forEach(item => setValue(item, formInitialValues[item]));
    }
  }, [setValue, formInitialValues]);

  const onSubmit = (data: IFormFieldValues) => {
    setIsLoading(true);
    feedbackRequests.clearError();
    if (screenshotImageRef.current) {
      const selectedFile = screenshotImageRef.current?.files?.item(0);
      const formData = new FormData();
      formData.append('requestId', requestId.current);
      formData.append('name', data.name);
      formData.append('email', data.email);
      formData.append('description', data.description);
      const info: {
        referrer: string;
        screenSize: Array<number>;
        params?: TKeyValuePairs;
      } = {
        referrer: document.referrer,
        screenSize: [
          (
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
          ),
          (
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight
          )
        ]
      };
      if (keyValuePairs.length) {
        info.params = keyValuePairs;
      }
      formData.append('info', JSON.stringify(info));
      if (formId) {
        formData.append('formId', formId);
      }
      if (projects.project) {
        formData.append('projectId', projects.project.id.toString());
        formData.append('projectSlug', projects.project.slug);
        formData.append('projectTitle', projects.project.title);
      }
      if (selectedFile) {
        formData.append('file', selectedFile);
      }
      feedbackRequests.createRequest(formData);
      setFormValues(formInitialValues);
      updateFormFromState(['name', 'email', 'description', 'file']);
      setIsPreviewVisible(false);
      setIsFormVisible(true);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${layout.pageTitle} — ${SITE_TITLE}`}</title>
      </Helmet>
      <FeedbackFormPageStyled>
        <Snackbar />
        <ActivityBlock isLoading={isLoading}>
          <Back to={'/'}>Все сервисы Рамблера</Back>
          <h2>Помощь по проекту «{projects?.project?.title}»</h2>
          <h1>{layout.pageTitle}</h1>
          {feedbacksPerHourOverLimit && (
            <Content>
              <Title>Мы уже получили ваше обращение</Title>
              <Description>Написать ещё одно обращение можно будет только через час</Description>
            </Content>
          )}
          {feedbacksPerDayOverLimit && (
            <Content>
              <Title>Мы получили все ваши обращения</Title>
              <Description>Написать еще одно обращение можно будет только завтра</Description>
            </Content>
          )}
          {!feedbacksPerHourOverLimit && !feedbacksPerDayOverLimit && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <PageBlock>
                <InfoWrapper>
                  <Info type="hint">
                    Для связи с вами укажите почту, в которую можете войти и прочесть письмо от нас
                  </Info>
                </InfoWrapper>
              </PageBlock>
              <PageBlock className={formStyle1}>
                <Controller
                  control={control}
                  name="name"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        placeholder={feedbackForms.form?.nameTitle ?? ''}
                        isError={errors.username}
                        value={field.value}
                        onChange={field.onChange}
                        refCallback={field.ref}
                      />
                      {errors.username?.type === 'required' && (
                        <ErrorMessage>Введите ваше имя</ErrorMessage>
                      )}
                    </>
                  )}
                />
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  rules={{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}}
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        placeholder={feedbackForms.form?.emailTitle ?? ''}
                        isError={errors.email}
                        value={field.value}
                        onChange={field.onChange}
                        refCallback={field.ref}
                      />
                      {errors.email?.type === 'required' && (
                        <ErrorMessage>Введите ваш e-mail</ErrorMessage>
                      )}
                    </>
                  )}
                />
              </PageBlock>
              <PageBlock className={formStyle2}>
                <Controller
                  control={control}
                  name="description"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <Textarea
                        placeholder={feedbackForms.form?.descriptionTitle ?? ''}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      {errors.text?.type === 'required' && (
                        <ErrorMessage>Опишите вашу проблему</ErrorMessage>
                      )}
                    </>
                  )}
                />
              </PageBlock>
              <PageBlock className={isFormVisible ? '' : hidden}>
                <p>
                  <Controller
                    control={control}
                    name="file"
                    defaultValue=""
                    render={({ field }) => (
                      <label>
                        <input
                          ref={screenshotImageRef}
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            const snackbarConfig: {
                              type: 'error';
                              [key: string]: number | string | boolean;
                            } = {
                              type: 'error',
                              align: 'bottom right',
                              multiline: true,
                              autoCloseDuration: 15000,
                              withCloseButton: true,
                            }
                            field.onChange(e);
                            if (e.target.files && e.target.files[0]) {
                              const file = e.target.files[0];
                              if (file.size > 10485760) {
                                e.target.value = '';
                                setFormValues({
                                  ...formValues,
                                  file: '',
                                });
                                snackbarEvent({
                                  ...snackbarConfig,
                                  message: 'Не удалось прикрепить файл. Размер не должен быть больше 10 МБ',
                                });
                              } else if (!isImage(file.name)) {
                                e.target.value = '';
                                setFormValues({
                                  ...formValues,
                                  file: '',
                                });
                                snackbarEvent({
                                  ...snackbarConfig,
                                  message: 'Не подходящий формат файла. Доступные форматы: PNG, GIF, JPEG, BMP',
                                });
                              } else if (e.target.files && e.target.files[0]) {
                                setFileInfo({
                                  preview: URL.createObjectURL(e.target.files[0]),
                                  previewName: e.target.files[0].name,
                                });
                                setIsFormVisible(false);
                                setIsPreviewVisible(true);
                              }
                            }
                          }}
                        />
                        Прикрепить скриншот
                      </label>
                    )}
                  />
                  {' '}Файл не должен быть больше 10 МБ
                </p>
              </PageBlock>
              <PageBlock className={isPreviewVisible ? '' : hidden}>
                <Preview>
                  <PreviewImage src={fileInfo.preview} alt="" />
                  <PreviewName>
                    {fileInfo.previewName}
                  </PreviewName>
                  <PreviewRemove
                    onClick={() => {
                      const fileInput = screenshotImageRef.current;
                      if (fileInput) {
                        fileInput.value = '';
                      }
                      setIsPreviewVisible(false);
                      setIsFormVisible(true);
                    }}
                  >
                    Удалить
                  </PreviewRemove>
                </Preview>
              </PageBlock>
              <PageBlock>
                <Button type="submit" disabled={!isEdited}>
                  Отправить
                </Button>
              </PageBlock>
              <PageBlock>
                <p>
                  Нажимая «Отправить», Вы принимаете{' '}
                  <a href="/legal/1145/">
                    регламент по рассмотрению жалоб и блокированию незаконного контента
                  </a>
                </p>
              </PageBlock>
            </form>
          )}
        </ActivityBlock>
      </FeedbackFormPageStyled>
    </React.Fragment>
  );
});

export default FeedbackForm;
