import React, { ReactElement, ReactNode } from 'react';

import { useRootStore } from '@stores/index';
import { EPageContext } from '@rambler-help/shared';
import { ReactComponent as BackIcon } from './images/back.svg';

import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import {
  fontMontserrat,
  fontColorLink,
  fontColorLinkHover,
  fontColorLinkActive
} from '@rambler-help/shared';

const Back = styled.div<{ context?: EPageContext }>`
  position: relative;
  margin-bottom: ${({ context = EPageContext.User }) => (
    context === EPageContext.Admin ? '10px' : '30px'
  )};

`;
const backIconStyle = css`
  position: absolute;
  top: 0;
  left: 0;
`;
const Link = styled.a`
  display: inline-block;
  padding: 0 0 0 25px;
  text-decoration: none;
  ${fontMontserrat}
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  ${fontColorLink}
  &:hover {
    ${fontColorLinkHover}
  }
  &:active {
    ${fontColorLinkActive}
  }
`;

interface BackComponentProps {
  to: string;
  children?: ReactNode;
}

const BackComponent = ({ to, children }: BackComponentProps): ReactElement => {
  const { layout } = useRootStore();
  return (
    <Back context={layout.pageContext}>
      <Link href={to}>
        <BackIcon className={backIconStyle} />
        {children}
      </Link>
    </Back>
  );
};

export default BackComponent;
